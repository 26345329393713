//
//
//
//
//
//
//
//
//
//
//

export default {
  head () {
    return this.getHead()
  },
  computed: {
    pageType () {
      if (this.errorPage) {
        return "error-page"
      }
      return this.activePage.type
    },
    allPages () {
      return this.$store.state.pages.all
    },
    allLegacyPages () {
      const queryOptions = this.$route.query
      if (this.objHasAndNotEmpty(queryOptions, "preview")) {
        if (queryOptions.preview === "1") {
          return Object.values(this.$store.state.legacypages.all)
        }
      }
      return Object.values(this.$store.state.legacypages.all).filter(
        p => p.draft === 0
      )
    },
    domainPageId: {
      get () {
        return this.$store.state.legacypages.domainPageId
      },
      set (value) {
        this.$store.commit("legacypages/SET_DOMAINPAGEID", value)
      }
    },
    pageId () {
      let id = this.$route.params.link
      const pageId = this.$route.params.page
      let domain = false
      if (process.browser) {
        domain = window.location.hostname.replace("www.", "").toLowerCase()
      }
      const domainPage = Object.values(this.allLegacyPages).find(
        page => page.domain.toLowerCase() === domain
      )
      if (domainPage) {
        id = "resources"
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.domainPageId = domainPage.id
      }
      if (!id) {
        return "home"
      }
      if (!pageId) {
        const taggedPages = Object.values(this.allPages).filter(p =>
          p.tags?.includes(id.toLowerCase()) ?? false
        )
        if (taggedPages.length > 0) {
          return "tags-list-page"
        }
      } else {
        const checkPage = Object.values(this.allPages).find(
          p => p.id === pageId && (p.tags?.includes(id.toLowerCase()) ?? false)
        )
        if (checkPage) {
          return pageId
        }
        return "error-page"
      }
      return id
    }
  },
  watch: {
    allPages () {
      if (!this.isEmptyObject(this.allPages)) {
        this.pagesLoaded()
      }
    }
  },
  mounted () {
    if (!this.isEmptyObject(this.allPages)) {
      this.pagesLoaded()
    }
  },
  methods: {
    pagesLoaded () {
      this.$store.commit("pages/active", this.pageId)
      if (this.objHasAndNotEmpty(this.activePage, "menu")) {
        if (this.activePage.menu.menu_only) {
          this.$router.push({
            path: "/"
          })
        }
      } else {
        this.$store.commit("pages/SET_ERROR_PAGE", true)
      }
    }
  }
}
